import React, { ReactElement } from 'react';
import { Button } from 'semantic-ui-react';
import { ResultDto } from '../../dto/ResultDto';
import { PatientResultPDFDocument } from './patient-results-pdf';
import ReactPDF, { pdf } from '@react-pdf/renderer';
// @ts-ignore
import { saveAs } from "file-saver";
import { UserDto } from '../../dto/UserDto';


interface ExportPatientResultButtonProps {
  data: ResultDto[];
  patient: UserDto;
  gameName: string
}

export const ExportPatientResultButton = ({ data, patient, gameName }
                                            : ExportPatientResultButtonProps): ReactElement  => {

  const generatePdf = async () => {
    const blob = await pdf(
      <PatientResultPDFDocument data={data} patient={patient} gameName={gameName}/>).toBlob();
    saveAs(blob, "patient_" + patient.lastName + "_" + gameName +"_"+ new Date().toLocaleDateString(undefined,
      {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'})
      + ".pdf");
  };


  return <Button onClick={() => generatePdf()}>Сгенерировать отчет</Button>
}