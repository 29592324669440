import { DiagnosesDto } from '../dto/DiagnosesDto';
import { DropdownItemProps } from 'semantic-ui-react';

export function diagnosesToDropDownItemProps(diagnoses: DiagnosesDto[]): DropdownItemProps[] {
  return diagnoses.map((item) => {
    const tmp: DropdownItemProps = {
      key: item.id,
      text: item.name,
      value: item.id
    };
    return tmp;
  });
}
