import { DropdownItemProps } from 'semantic-ui-react';
import { AffectedHand } from '../enums/affected-hand';

const dropDownHandOptions: DropdownItemProps[] = [
  {
    key: AffectedHand.LEFT,
    text: 'Левая',
    value: AffectedHand.LEFT
  },
  {
    key: AffectedHand.RIGHT,
    text: 'Правая',
    value: AffectedHand.RIGHT
  }
];

export { dropDownHandOptions };
