import {Page, Text, View, Document, StyleSheet, Font, Image} from '@react-pdf/renderer';
import { ResultDto } from '../../dto/ResultDto';
import React, { ReactElement } from 'react';
import { UserDto } from '../../dto/UserDto';
import { getSexByName } from '../../utils/sex';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { convertDateToFormattedString } from '../../pages/patients/results/patient-results';
import ChartJSImage from 'chart.js-image';
import { format, parseISO } from 'date-fns';

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

const styles = StyleSheet.create({
  page: { flexDirection: 'column', backgroundColor: 'white' },
  content: { paddingLeft: 40, paddingRight: 20, marginBottom: 40 },
  header: { position: "relative", width: '100%', height: '50pt', backgroundColor: '#80c783' },
  logoText: { fontFamily: "Roboto", margin: 15, color: 'white' , textAlign: "center", fontSize: 14 },
  headSection: { marginTop: 20, marginBottom: 10 },
  section: { marginTop: 30, marginBottom: 10 },
  list: { flexDirection: "row" },
  listCol: { marginLeft: 60 },
  headerText: { fontFamily: "Roboto", textAlign: "center" },
  subHeaderText: { fontFamily: "Roboto", textAlign: "center", fontSize: 14 },
  text: { fontFamily: "Roboto", fontSize: 12 },
  tableText: { fontFamily: "Roboto", fontSize: 9, margin: 3 },
  table: { marginLeft: 10, marginRight: 10 }
});

interface PatientResultsPdfProps {
  data: ResultDto[];
  patient: UserDto;
  gameName: string
}

export const PatientResultPDFDocument = ({ data, patient, gameName } : PatientResultsPdfProps): ReactElement => {

  // @ts-ignore
  const chart = new ChartJSImage().chart({
    type: 'line',
    data: {
      labels: data.map(value => {
        const date = parseISO(value.time);
        try {
          const formatted = format(date, 'dd.MM.yy HH:mm');
          console.log(formatted);
          return formatted;
        } catch (e) {
          console.error(e);
        }
        return value.time;
      }).reverse(),
      datasets: [{
        label: "Результат",
        fill: false,
        data: data.map(value => value.value).reverse()
      }]
    },
    options: {
      legend: { labels: { fontSize: 8 } },
      scales: {
        yAxes: [ { ticks: { fontSize: 10 } } ],
        xAxes: [ { ticks: { fontSize: 10 } } ]
      }
    }
  }).backgroundColor('white').width('500').height('300');

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.logoText}>Девирта.Точность</Text>
        </View>
        <View style={styles.content}>
          <View style={styles.headSection}>
            <Text style={styles.headerText}>
              Отчет для пациента: {`${patient.lastName} ${patient.firstName}\n`}по игре-упражнению: {gameName}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subHeaderText}>Данные пациента</Text>
          </View>
          <View style={styles.list}>
            <View>
              <Text style={styles.text}>ФИО</Text>
              <Text style={styles.text}>E-mail</Text>
              <Text style={styles.text}>Пол</Text>
              <Text style={styles.text}>Дата рождения</Text>
              <Text style={styles.text}>Номер телефона</Text>
            </View>
            <View style={styles.listCol}>
              <Text style={styles.text}>{patient.lastName} {patient.firstName} {patient.patronymic}</Text>
              <Text style={styles.text}>{patient.email}</Text>
              <Text style={styles.text}>{getSexByName(patient.sex).text}</Text>
              <Text style={styles.text}>{patient.birthday}</Text>
              <Text style={styles.text}>{patient.phone}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.subHeaderText}>Результаты: </Text>
          </View>
          {data.length > 0 ?
            <View>
              <View>
                <Image src={chart.toURL()}/>
              </View>
              <View style={styles.table}>
                <Table data={
                  data.map((item) => ({
                    date: item.time,
                    status: item.isCanceled ? 'Отменено' : 'Завершено',
                    duration: item.duration,
                    value: item.value + ' ' + item.game.resultUserName
                  }))}>
                  <TableHeader includeLeftBorder={false} includeRightBorder={false}>
                    <TableCell style={styles.tableText}>Время</TableCell>
                    <TableCell style={styles.tableText}>Статус</TableCell>
                    <TableCell style={styles.tableText}>Длительность</TableCell>
                    <TableCell style={styles.tableText}>Результат</TableCell>
                  </TableHeader>
                  <TableBody includeLeftBorder={false} includeRightBorder={false}>
                    <DataTableCell style={styles.tableText} getContent={(r) => convertDateToFormattedString(r.date)}/>
                    <DataTableCell style={styles.tableText} getContent={(r) => r.status}/>
                    <DataTableCell style={styles.tableText} getContent={(r) => r.duration}/>
                    <DataTableCell style={styles.tableText} getContent={(r) => r.value}/>
                  </TableBody>
                </Table>
              </View>
            </View>
            :
            <Text style={styles.text}>Нет результатов</Text>
          }
        </View>
      </Page>
    </Document>
  )
}

