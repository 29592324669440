import React from 'react';
import { UserDto } from '../../../dto/UserDto';
import { Button, List } from 'semantic-ui-react';

export interface AvailablePatientListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  user: UserDto;
  onAddClick: (id: number) => void;
}

export default function AvailablePatientListItem({ user, onAddClick }: AvailablePatientListItemProps) {
  return (
    <List.Item>
      <List.Content floated={'right'}>
        <Button
          content="Добавить"
          icon="right add"
          labelPosition="right"
          onClick={() => {
            onAddClick(user.id);
          }}
        />
      </List.Content>
      <List.Header>
        {user.lastName} {user.firstName} {user.patronymic}
      </List.Header>
      <List.Description>Номер телефона: {user.phone}</List.Description>
    </List.Item>
  );
}
