import React from 'react';
import { Form } from 'semantic-ui-react';

interface SecondNameInputProps {
  secondName: string;
  onChangeSecondName: (value: string) => void;
}

const SecondNameInput = ({ secondName, onChangeSecondName }: SecondNameInputProps): JSX.Element => {
  return (
    <Form.Input
      value={secondName}
      onChange={(event) => {
        onChangeSecondName(event.target.value);
      }}
      required
      placeholder={'Фамилия'}
      label={'Фамилия'}
    />
  );
};

export default SecondNameInput;
