import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';
import { deleteResult, getResults } from '../../../api/routes/results';
import { PatientResultsWithDiseasesAndGame } from '../../../dto/PatientResultsWithDiseasesAndGame';
import ResultsTable from '../../../components/pateints/games/results/results-table';
import ConfirmDialog from '../../../components/confirm/confirm-dialog';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import { ResultDto } from '../../../dto/ResultDto';
import { DateType } from '../../../utils/date';
import LabeledDatePicker from '../../../components/form/date/labeled-date-picker';
import styles from './patient-results.module.css';
import { ExportPatientResultButton } from '../../../components/export_pdf/export-patient-result-button';

interface ParamsType {
  id: string;
  diseasesId: string;
  gameId: string;
}

type DatedResult = {
  result: ResultDto;
  dateTimed: DateType;
};

const filterByDate = (from: DateType, end: DateType, data: ResultDto[]): ResultDto[] => {
  return data
    .map((value) => {
      const a: DatedResult = {
        dateTimed: parseISO(value.time),
        result: value
      };
      return a;
    })
    .filter((value) => (from && value.dateTimed && value.dateTimed >= from) || !from)
    .filter((value) => (end && value.dateTimed && value.dateTimed <= end) || !end)
    .map((value) => value.result);
};

export default function PatientResults(): ReactElement {
  const { id, diseasesId, gameId } = useParams<ParamsType>();
  const [data, setData] = useState<PatientResultsWithDiseasesAndGame | null>(null);
  const [deleteModal, setDeleteModal] = useState<ReactElement | null>(null);
  const [filteredData, setFilteredData] = useState<ResultDto[]>([]);
  const [fromDate, setFromDate] = useState<DateType>(null);
  const [endDate, setEndDate] = useState<DateType>(null);

  const fetchAsync = async () => {
    const fetched = await getResults(id, diseasesId, gameId);
    setData(fetched);
  };

  useEffect(() => {
    if (data) {
      console.log(filterByDate(fromDate, endDate, data.results));
      setFilteredData(filterByDate(fromDate, endDate, data.results));
    }
  }, [data, fromDate, endDate]);

  const handleOnResultDelete = async (id: number) => {
    await deleteResult(id);
    await fetchAsync();
  };

  const handleOnResultDeleteButtonClick = (resultId: number) => {
    setDeleteModal(
      <ConfirmDialog
        title={'Подтвердите действие'}
        message={`Вы действительно хотите удалить результат ?`}
        isOpen={true}
        setOpen={() => setDeleteModal(null)}
        onConfirm={() => {
          handleOnResultDelete(resultId);
          setDeleteModal(null);
        }}
      />
    );
  };

  useEffect(() => {
    fetchAsync();
  }, []);

  return (
    <>
      {data &&
        <ExportPatientResultButton
          patient={data.user}
          data={filteredData}
          gameName={data.gameDto.name}
        />
      }
      <Grid>
        <Grid.Row verticalAlign={'bottom'}>
          <Grid.Column width={'10'}>
            {data && <h1>{`${data.gameDto.name}: ${data.user.lastName} ${data.user.firstName}`}</h1>}
          </Grid.Column>
          <Grid.Column width={'3'}>
            <LabeledDatePicker
              label={'Дата начала'}
              required={false}
              date={fromDate}
              onChange={setFromDate}
              className={styles.datePicker}
            />
          </Grid.Column>
          <Grid.Column width={'3'}>
            <LabeledDatePicker
              label={'Дата конца'}
              required={false}
              date={endDate}
              onChange={setEndDate}
              className={styles.datePicker}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        <ResponsiveContainer height={250} width="100%">
          <LineChart
            data={filteredData.map((i) => {
              return { name: convertDateToFormattedString(i.time), value: i.value };
            })}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              labelFormatter={(label) => {
                console.log(label);
                return label;
              }}
            />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
        {data && <ResultsTable results={filteredData} onDeleteClick={handleOnResultDeleteButtonClick} />}
      </Segment>
      {deleteModal}
    </>
  );
}

export function convertDateToFormattedString(time: string): string {
  const date = parseISO(time);
  try {
    const formatted = format(date, 'd MMM yyyy HH:mm');
    console.log(formatted);
    return formatted;
  } catch (e) {
    console.error(e);
  }
  return time;
}
