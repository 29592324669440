import { UserDto } from '../dto/UserDto';

export const filterUsersBySearchValue = (patients: UserDto[], searchValue: string): UserDto[] => {
  if (searchValue !== '') {
    const sv = searchValue.toLowerCase();
    return patients.filter(
      (v) =>
        v.firstName.toLowerCase().includes(sv) ||
        v.lastName.toLowerCase().includes(sv) ||
        v.phone.toLowerCase().includes(sv)
    );
  } else {
    return patients;
  }
};
