import React, { ReactElement } from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './pages/login/login';
import { CommonPage } from './pages/common-page';

function App(): ReactElement {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/login'}>
          <Login />
        </Route>
        <Route path={'/patients'}>
          <CommonPage />
        </Route>
        <Route path={'/'}>
          <Redirect to={'/login'} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
