import ky from 'ky';
import { API_URL } from '../constants/Url';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const HttpClient = ky.extend({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = cookies.get('token');
        if (token) {
          request.headers.set('Authorization', `Basic ${token}`);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          cookies.remove('token');
          if(!request.url.includes('login')){
            window.location.href = '/';
          }
        }
      }
    ]
  }
});
