import React from 'react';
import { ResultDto } from '../../../../dto/ResultDto';
import { Table } from 'semantic-ui-react';
import ResultItemComponent from './result-item-component';

export interface ResultsTableProps {
  results: ResultDto[];
  onDeleteClick: (id: number) => void;
}

export default function ResultsTable({ results, onDeleteClick }: ResultsTableProps) {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Время</Table.HeaderCell>
          <Table.HeaderCell>Статус</Table.HeaderCell>
          <Table.HeaderCell>Длительность</Table.HeaderCell>
          <Table.HeaderCell>Результат</Table.HeaderCell>
          <Table.HeaderCell>Действие</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {results.sort((a, b) => {
          if(a.time < b.time) return 1;
          if (a.time > b.time) return -1;
          return 0;
        }).map((value) => (
          <ResultItemComponent key={value.id} resultDto={value} onDeleteClick={onDeleteClick} />
        ))}
      </Table.Body>
    </Table>
  );
}
