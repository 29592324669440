import React, { ReactElement, useEffect, useState } from 'react';
import { UserDto } from '../dto/UserDto';
import { DHeader } from '../components/header';
import { Container } from 'semantic-ui-react';
import { Patients } from './patients/patients';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { HttpClient } from '../ky/HttpClient';
import { CreatePatient } from './patients/create/create-patient';
import { DetailedPatient } from './patients/detailed/detailed-patient';
import PatientResults from './patients/results/patient-results';
import PatientsAvailable from './patients/available/patients-available';
import PatientCreateDiseases from './patients/detailed/diseases/create/patient-create-diseases';
import PatientEditDiseases from './patients/detailed/diseases/edit/patient-edit-diseases';
import EditPatient from './patients/detailed/edit/edit-patient';

export function CommonPage(): ReactElement {
  const { path } = useRouteMatch();
  const [cookies] = useCookies(['token']);
  const [currentUser, setCurrentUser] = useState<UserDto | null>(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user: UserDto = await HttpClient.get('users/me').json<UserDto>();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  return (
    <>
      {!cookies['token'] ? (
        <Redirect to={'/login'} />
      ) : (
        <>
          <DHeader currentUser={currentUser} style={{ marginBottom: 64 + 'px' }} />
          <Container>
            <Switch>
              <Route exact={true} path={path}>
                <Patients />
              </Route>
              <Route exact={true} path={`${path}/create`}>
                <CreatePatient />
              </Route>
              <Route exact={true} path={`${path}/available`}>
                <PatientsAvailable />
              </Route>
              <Route path={`${path}/detailed/results/:id/:diseasesId/:gameId`}>
                <PatientResults />
              </Route>
              <Route path={`${path}/detailed/:id/add`}>
                <PatientCreateDiseases />
              </Route>
              <Route path={`${path}/detailed/:id/edit`}>
                <EditPatient />
              </Route>
              <Route path={`${path}/detailed/:id/diseases/edit`}>
                <PatientEditDiseases />
              </Route>
              <Route path={`${path}/detailed/:id`}>
                <DetailedPatient />
              </Route>
            </Switch>
          </Container>
        </>
      )}
    </>
  );
}
