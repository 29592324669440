import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Button, Grid, Header, Input } from 'semantic-ui-react';
import { PatientList } from './patient-list';
import { Link, useRouteMatch } from 'react-router-dom';
import { PatientDto } from '../../dto/PatientDto';
import { getMyPatients } from '../../api/routes/users';
import { filterPatientsBySearchValue } from '../../utils/patients';

export function Patients(): ReactElement {
  const { url } = useRouteMatch();
  const [patients, setPatients] = useState<PatientDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredPatients, setFilteredPatients] = useState<PatientDto[]>(patients);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onSearchChangeHandler = (event: ChangeEvent, data: object) => {
    // @ts-ignore
    setSearchValue(data.value);
  };

  useEffect(() => {
    const fetchPatientsAsync = async () => {
      setLoading(true);
      const result: PatientDto[] = await getMyPatients();
      setPatients(result);
      setLoading(false);
    };
    fetchPatientsAsync();
  }, []);

  useEffect(() => {
    setFilteredPatients(filterPatientsBySearchValue(patients, searchValue));
  }, [patients, searchValue]);

  return (
    <>
      <Grid style={{ marginBottom: 8 + 'px' }}>
        <Grid.Row verticalAlign={'middle'}>
          <Grid.Column width={2}>
            <Header size={'large'}>Пациенты</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button as={Link} to={`${url}/create`} primary={true}>
              Зарегистрировать
            </Button>
          </Grid.Column>
          <Grid.Column width={2}>
            {/*<Button as={Link} to={`${url}/available`}>*/}
            {/*  Выбрать*/}
            {/*</Button>*/}
          </Grid.Column>
          <Grid.Column floated={'right'} width={4}>
            <Input
              value={searchValue}
              onChange={onSearchChangeHandler}
              icon={'search'}
              placeholder={'Поиск...'}
              style={{ width: 100 + '%' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PatientList patients={filteredPatients} isLoading={isLoading} />
    </>
  );
}
