import React from 'react';
import { DateType } from '../../../utils/date';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';

interface LabeledDatePickerProps {
  label: string;
  required: boolean;
  date: DateType;
  onChange: (date: DateType) => void;
  className: string;
}

const LabeledDatePicker = ({ label, date, onChange, required, className }: LabeledDatePickerProps): JSX.Element => {
  function onChangeDateHandler(event: React.SyntheticEvent<Element, Event> | undefined, data: SemanticDatepickerProps) {
    onChange(data.value);
  }

  return (
    <SemanticDatepicker
      label={label}
      required={required}
      locale={'ru-RU'}
      type={'basic'}
      value={date}
      className={className}
      onChange={onChangeDateHandler}
    />
  );
};

export default LabeledDatePicker;
