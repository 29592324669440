import { GameDto } from '../dto/GameDto';
import { DropdownItemProps } from 'semantic-ui-react';

export function gamesToDropDownItemProps(games: GameDto[]): DropdownItemProps[] {
  return games.map((item) => {
    const tmp: DropdownItemProps = {
      key: item.id,
      text: item.name,
      value: item.id
    };
    return tmp;
  });
}
