import { PatientDto } from '../dto/PatientDto';

export const filterPatientsBySearchValue = (patients: PatientDto[], searchValue: string): PatientDto[] => {
  if (searchValue !== '') {
    const sv = searchValue.toLowerCase();
    return patients.filter(
      (v) =>
        v.userDto.firstName.toLowerCase().includes(sv) ||
        v.userDto.lastName.toLowerCase().includes(sv) ||
        v.userDto.phone.toLowerCase().includes(sv)
    );
  } else {
    return patients;
  }
};
