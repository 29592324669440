import React, { ReactElement } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type ConfirmDialogProps = {
  title: string;
  message: string;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
};

export default function ConfirmDialog({
  title,
  message,
  isOpen,
  setOpen,
  onConfirm
}: ConfirmDialogProps): ReactElement {
  return (
    <Modal onOpen={() => setOpen(true)} onClose={() => setOpen(false)} open={isOpen}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Отмена</Button>
        <Button content="Да" onClick={() => onConfirm()} negative />
      </Modal.Actions>
    </Modal>
  );
}
