import React, { ReactElement } from 'react';
import { Grid } from 'semantic-ui-react';
import styles from './detailed-patient.module.css';

export interface DetailedPatientInfoFieldProps {
  fieldName: string;
  value: string;
}

export default function DetailedPatientInfoField({ fieldName, value }: DetailedPatientInfoFieldProps): ReactElement {
  return (
    <Grid.Row columns={2} className={styles.detailedPatientInfoFieldRow}>
      <Grid.Column width={3}>
        <p className={`${styles.patientInfoFieldName} ${styles.patientInfoText}`}>{fieldName}:</p>
      </Grid.Column>
      <Grid.Column width={5}>
        <p className={styles.patientInfoText}> {value} </p>
      </Grid.Column>
    </Grid.Row>
  );
}
