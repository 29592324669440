import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GameDto } from '../../../../../dto/GameDto';
import { DiagnosesDto } from '../../../../../dto/DiagnosesDto';
import { getAllGames } from '../../../../../api/routes/games';
import { getAllDiagnoses } from '../../../../../api/routes/diagnoses';
import { Form, Header, Message, Segment } from 'semantic-ui-react';
import { formatISO } from 'date-fns';
import { NewDiseasesDto } from '../../../../../dto/NewDiseasesDto';
import { createDiseases, isAvailableDates } from '../../../../../api/routes/diseases';
import { getPatientById } from '../../../../../api/routes/users';
import { PatientDto } from '../../../../../dto/PatientDto';
import GamesDropDown from '../../../../../components/diseases/form/games-drop-down';
import DiagnosesDropDown from '../../../../../components/diseases/form/diagnoses-drop-down';
import { dropDownHandOptions } from '../../../../../utils/affected-hand';
import AffectedHandDropDown from '../../../../../components/diseases/form/affected-hand-drop-down';
import LabeledDatePicker from '../../../../../components/form/date/labeled-date-picker';

interface ParamsTypes {
  id: string;
}

export default function PatientCreateDiseases(): ReactElement {
  const { id } = useParams<ParamsTypes>();
  const history = useHistory();
  const [games, setGames] = useState<GameDto[]>([]);
  const [selectedGames, setSelectedGames] = useState<number[]>([]);
  const [diagnoses, setDiagnoses] = useState<DiagnosesDto[]>([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<number | undefined>();
  const [affectedHand, setAffectedHand] = useState(dropDownHandOptions[0]);
  const [startDate, setStartDate] = useState<Date | Date[] | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | Date[] | null | undefined>(null);
  const [patient, setPatient] = useState<PatientDto | null>(null);
  const [error, setError] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActiveSaveButton, setActiveButton] = useState<boolean>(false);

  useEffect(() => {
    setActiveButton(
      selectedDiagnoses !== undefined &&
        startDate !== undefined &&
        startDate !== null &&
        endDate !== undefined &&
        endDate !== null &&
        startDate < endDate
    );
  }, [selectedDiagnoses, startDate, endDate]);

  const fetchAndSetGames = async () => {
    const fetched = await getAllGames();
    setGames(fetched);
  };

  const fetchAndSetDiagnoses = async () => {
    const fetched = await getAllDiagnoses();
    setDiagnoses(fetched);
  };

  const fetchAndSetPatient = async () => {
    const fetched = await getPatientById(id);
    setPatient(fetched);
  };

  async function handleOnSaveButtonClick() {
    setIsLoading(true);
    const isDateOk = await isAvailableDates(patient!.userDto.id, startDate as Date, endDate as Date);
    if (isDateOk) {
      const newDiseases: NewDiseasesDto = {
        affectedHand: affectedHand.value as string,
        diagnosesId: selectedDiagnoses!,
        gamesIds: selectedGames,
        patientId: id,
        startDate: formatISO(startDate as Date, { representation: 'date' }),
        endDate: formatISO(endDate as Date, { representation: 'date' })
      };
      await createDiseases(newDiseases);
      setIsLoading(false);
      history.push(`patients/detailed/${patient!.userDto.id}`);
    } else {
      setError('Указанный период времени пересекается с существующими');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAndSetGames();
    fetchAndSetPatient();
    fetchAndSetDiagnoses();
  }, []);

  return (
    <>
      {patient && (
        <Header size={'large'}>
          Добавление болезни пациенту: {patient.userDto.lastName} {patient.userDto.firstName.charAt(0).toUpperCase()}.
        </Header>
      )}
      <Form size={'large'} loading={isLoading}>
        <Segment>
          {error && (
            <Message negative>
              <Message.Header>Ошибка при создании</Message.Header>
              <p>{error}</p>
            </Message>
          )}
          <GamesDropDown allGames={games} selectedGamesIds={selectedGames} onChangeGames={setSelectedGames} />
          <DiagnosesDropDown
            diagnoses={diagnoses}
            selectedDiagnosesId={selectedDiagnoses}
            onSelectDiagnoses={setSelectedDiagnoses}
          />
          <AffectedHandDropDown affectedHandValue={affectedHand} onChangeAffectedHand={setAffectedHand} />
          <LabeledDatePicker
            label={'Дата начала'}
            required={true}
            date={startDate}
            onChange={setStartDate}
            className={''}
          />
          <LabeledDatePicker label={'Дата конца'} required={true} date={endDate} onChange={setEndDate} className={''} />
          <Form.Button primary onClick={handleOnSaveButtonClick} disabled={!isActiveSaveButton}>
            Сохранить
          </Form.Button>
        </Segment>
      </Form>
    </>
  );
}
