import React, { ReactElement } from 'react';
import { Button, List } from 'semantic-ui-react';
import { Link, useRouteMatch } from 'react-router-dom';
import { PatientDto } from '../../dto/PatientDto';

export interface PatientListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  patient: PatientDto;
}

export function PatientListItem({ patient }: PatientListItemProps): ReactElement {
  const { url } = useRouteMatch();

  return (
    <List.Item>
      <List.Content floated={'right'}>
        <Button
          as={Link}
          to={`${url}/detailed/${patient.userDto.id}`}
          content="Просмотреть"
          icon="right arrow"
          labelPosition="right"
        />
      </List.Content>
      <List.Header>
        {patient.userDto.lastName} {patient.userDto.firstName} {patient.userDto.patronymic}
      </List.Header>
      <List.Description>Номер телефона: {patient.userDto.phone}</List.Description>
    </List.Item>
  );
}
