import { NewDiseasesDto } from '../../../dto/NewDiseasesDto';
import { HttpClient } from '../../../ky/HttpClient';
import { DiseaseDto } from '../../../dto/DiseaseDto';
import { formatISO } from 'date-fns';

const createDiseases = async (newDiseases: NewDiseasesDto): Promise<Response> => {
  return HttpClient.post('diseases/', {
    json: newDiseases
  });
};

const updateDiseasesById = async (diseasesId: string, dto: NewDiseasesDto): Promise<Response> => {
  return HttpClient.post(`diseases/${diseasesId}`, {
    json: dto
  });
};

const getDiseasesById = async (id: string): Promise<DiseaseDto> => {
  return await HttpClient.get(`diseases/${id}`).json();
};

const isAvailableDates = async (patientId: number, startDate: Date, endDate: Date): Promise<boolean> => {
  return await HttpClient.post(`diseases/available/${patientId}`, {
    json: {
      startDate: formatISO(startDate, { representation: 'date' }),
      endDate: formatISO(endDate, { representation: 'date' })
    }
  }).json();
};

const deleteDiseases = async (id: string): Promise<Response> => HttpClient.delete(`diseases/${id}`);

export { createDiseases, updateDiseasesById, deleteDiseases, getDiseasesById, isAvailableDates };
