import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { phoneRexEx } from '../../../../utils/re';

interface PhoneNumberInputProps {
  phoneNumber: string;
  onChangePhoneNumber: (value: string) => void;
  disabled: boolean
}

const PhoneNumberInput = ({ phoneNumber, onChangePhoneNumber, disabled }: PhoneNumberInputProps): JSX.Element => {
  const [error, setError] = useState(false);

  return (
    <Form.Input
      value={phoneNumber}
      onChange={(event) => {
        setError(!phoneRexEx.test(String(event.target.value)))
        onChangePhoneNumber(event.target.value);
      }}
      disabled={disabled}
      required
      placeholder={'Номер телефона'}
      label={'Номер телефона'}
      error={error ? 'Неправильный формат телефона (телефон должен состоять из 10 цифр без 8)' : null}
    />
  );
};

export default PhoneNumberInput;
