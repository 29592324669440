import React, { CSSProperties, ReactElement } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { PatientListItem } from './patient-list-item';
import { PatientDto } from '../../dto/PatientDto';

export interface PatientList extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  patients: PatientDto[];
}

const emptyDataPStyles: CSSProperties = {
  textAlign: 'center',
  fontSize: '22px'
};

export function PatientList({ patients, ...other }: PatientList): ReactElement {
  return (
    <div {...other}>
      <Segment>
        {patients.length === 0 && <p style={emptyDataPStyles}>Нет данных</p>}
        <List divided={true} size={'big'}>
          {patients.map((v) => (
            <PatientListItem key={v.userDto.id} patient={v} />
          ))}
        </List>
      </Segment>
    </div>
  );
}
