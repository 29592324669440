import { PatientResultsWithDiseasesAndGame } from '../../../dto/PatientResultsWithDiseasesAndGame';
import { HttpClient } from '../../../ky/HttpClient';

const getResults = async (
  patientId: string,
  diseasesId: string,
  gameId: string
): Promise<PatientResultsWithDiseasesAndGame> => {
  return await HttpClient.get(`results/${patientId}/${diseasesId}/${gameId}`).json();
};

const deleteResult = async (resultId: number): Promise<Response> => {
  return HttpClient.delete(`results/${resultId}`);
};

export { getResults, deleteResult };
