import { PatientDto } from '../../../dto/PatientDto';
import { UserDto } from '../../../dto/UserDto';
import { HttpClient } from '../../../ky/HttpClient';
import { CreateUserDto } from '../../../dto/CreateUserDto';

const getPatientById = async (id: string): Promise<PatientDto> => {
  return await HttpClient.get(`users/patient/${id}`).json<PatientDto>();
};

const getUserById = async (id: string): Promise<UserDto> => {
  return await HttpClient.get(`users/id/${id}`).json<UserDto>();
};

const existsByPhone = async (phone: string): Promise<boolean> => {
  return await HttpClient.get(`users/exists/${phone}`).json<boolean>();
};

const login = async (token: string): Promise<UserDto> => {
  return await HttpClient.post('users/login', {
    headers: {
      Authorization: `Basic ${token}`
    }
  }).json<UserDto>();
};

const getMyPatients = async (): Promise<PatientDto[]> => {
  return await HttpClient.get('users/patients/me').json<PatientDto[]>();
};

const getAvailableUsers = async (): Promise<UserDto[]> => {
  return await HttpClient.get('users/available').json();
};

const createPatient = async (newUser: CreateUserDto): Promise<Response> => {
  return HttpClient.post('users/', {
    json: newUser
  });
};

const updatePatient = async (id: number, dto: CreateUserDto): Promise<Response> => {
  return HttpClient.post(`users/update/${id}`, {
    json: dto
  });
};

const addPatientToDoctor = async (patientId: number): Promise<Response> => {
  return HttpClient.post(`users/${patientId}`);
};

const deletePatientFromDoctor = async (patientId: number): Promise<Response> => {
  return HttpClient.delete(`users/${patientId}`);
};

export {
  getPatientById,
  getUserById,
  existsByPhone,
  login,
  getMyPatients,
  createPatient,
  updatePatient,
  getAvailableUsers,
  addPatientToDoctor,
  deletePatientFromDoctor
};
